import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider } from "react-router-dom";

import './assets/scss/app.scss'
import Router from './router';

const rotas = Router();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <React.StrictMode>
    <Suspense>
      <RouterProvider router={rotas} />
    </Suspense>
  </React.StrictMode>
);

